import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-blue-900 hover:text-indigo-700" smooth to="/#about">
                About
            </HashLink>
            {/* <HashLink className="px-4 font-extrabold text-blue-900 hover:text-indigo-700" smooth to="/#services">
                Services
            </HashLink> */}
            {/* <HashLink className="px-4 font-extrabold text-blue-900 hover:text-indigo-700" to="/">
                Portfolio
            </HashLink> */}
            <HashLink className="px-4 font-extrabold text-blue-900 hover:text-indigo-700" to="mailto:deepmeshlabs@gmail.com">
                Contact Us
            </HashLink>
            {/* <HashLink className="text-white bg-indigo-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
                Demo our products
            </HashLink> */}
        </>
    )
}

export default NavLinks;
